import firebase from "firebase"
import Package from "../../package.json"
import api from "./api"
import { getEnderecoAtualStorage } from "./StorageManager"

const Teste = () => {
    // return false;
    return !!(
        localStorage.getItem("teste") ||
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("picanhariaporteira.com.br")
    )
}

function atualizarDataCardapio() {
    localStorage.setItem("dateCardapioCF", new Date().getTime())
}

export function cripto(stringNew) {
    try {
        if (Teste()) {
            return stringNew
        }
        return btoa(stringNew)
    } catch (error) {}
}

export function descripto(stringNew) {
    try {
        if (Teste()) {
            return JSON.parse(stringNew)
        }
        return JSON.parse(atob(stringNew))
    } catch (error) {}
}

export function verificarLocalStorageAppDados() {
    let retorno = {}
    try {
        retorno = descripto(localStorage.getItem("app"))
    } catch (error) {
        retorno = {}
    }
    if (!retorno) {
        try {
            retorno = JSON.parse(localStorage.getItem("aplicativoCF"))
        } catch (error) {
            retorno = {}
        }
    }
    return retorno
}

export function verificarLocalStorageIzza() {
    try {
        return JSON.parse(localStorage.getItem("usuarioIzzaCF"))
    } catch (error) {
        return null
    }
}

export function novoCarrinho() {
    return JSON.parse(JSON.stringify(carrinho))
}

const carrinho = {
    valorMinimoRetiradaNoLocal: null,
    valorMinimoPedido: null,
    valorDesconto: null,
    percentualDesconto: null,
    minimoEntregaGratis: null,
    maximoPedidoComDesconto: null,
    maximoDesconto: null,
    cupomDesconto: null,
    pedido: {
        entregaImediata: false,
        formaPagamento: {},
        itens: [],
        taxaEntrega: null,
        tokenCartaoCredito: null,
        troco: null,
        valorDesconto: null,
        valorEntrega: null,
        valorTotal: 0,
        token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
    },
}

export function verificarLocalStorageCarrinho() {
    let retorno = JSON.parse(JSON.stringify(carrinho))
    try {
        retorno = descripto(localStorage.getItem("car"))
    } catch (error) {
        retorno = {}
    }
    if (!retorno) {
        try {
            retorno = JSON.parse(localStorage.getItem("carrinhoCF"))
        } catch (error) {
            retorno = {}
        }
    }
    if (!retorno) {
        retorno = JSON.parse(JSON.stringify(carrinho))
    }
    return retorno
}

export function verificarLocalStorageEndereco() {
    let retorno = {}
    // try {
    //     retorno = localStorage.getItem("car")
    // } catch (error) {
    //     retorno = {}
    // }
    // if(!retorno){
    try {
        if (getEnderecoAtualStorage()) {
            retorno = getEnderecoAtualStorage()
        }
    } catch (error) {
        retorno = {}
    }
    // }
    return retorno
}
export function verificarLocalStorageCardapio() {
    let retorno = {}
    // try {
    //     retorno = localStorage.getItem("car")
    // } catch (error) {
    //     retorno = {}
    // }
    // if(!retorno){
    try {
        if (sessionStorage.getItem("cardapioCF")) {
            retorno = JSON.parse(sessionStorage.getItem("cardapioCF"))
        }
    } catch (error) {
        retorno = {}
    }
    // }
    return retorno
}

export function verificarLocalStorageUsuario() {
    let retorno = null
    try {
        retorno = descripto(localStorage.getItem("use"))
    } catch (error) {
        retorno = null
    }
    if (!retorno) {
        try {
            retorno = JSON.parse(localStorage.getItem("usuarioCF"))
        } catch (error) {
            retorno = null
        }
    }
    return retorno
}

export function verificarLocalStorageEstabelecimentoAtual() {
    let retorno = {}
    try {
        retorno = descripto(localStorage.getItem("esta"))
    } catch (error) {
        retorno = {}
    }
    if (!retorno) {
        try {
            retorno = JSON.parse(localStorage.getItem("estabelecimentoAtualCF"))
        } catch (error) {
            retorno = {}
        }
    }
    return retorno
}

export function CorClara(hex) {
    // console.warn('hex', hex)
    if (hex) {
        var r, g, b, longo
        hex = hex.replace("#", "")
        longo = hex.length > 3

        r = longo ? parseInt(hex.substr(0, 2), 16) : parseInt(hex.substr(0, 1), 16) * 17
        g = longo ? parseInt(hex.substr(2, 2), 16) : parseInt(hex.substr(1, 1), 16) * 17
        b = longo ? parseInt(hex.substr(4, 2), 16) : parseInt(hex.substr(2, 1), 16) * 17

        return (r * 299 + g * 587 + b * 114) / 1000 > 128
    }
    return false
}

export function getMoney(str) {
    var tmp = str.replace(/[\D]+/g, "")
    return parseInt(tmp === "" ? 0 : tmp)
}

export function formatReal(int) {
    var tmp = int + ""
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1")
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2")

    return tmp
}

export function isNumeric(str) {
    if (typeof str === "number") return true
    if (typeof str !== "string") return false

    var er = /^[0-9]+$/
    return er.test(str)
}

export function TestaCPF(CPF) {
    const strCPF = CPF?.replaceAll(".", "")?.replace("-", "")
    var Soma
    var Resto
    Soma = 0
    if (strCPF === "00000000000") return false

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
    Resto = (Soma * 10) % 11

    if (Resto === 10 || Resto === 11) Resto = 0
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false

    Soma = 0
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
    Resto = (Soma * 10) % 11

    if (Resto === 10 || Resto === 11) Resto = 0
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false
    return true
}

export const identificarEstabelecimentoSelecionado = (
    aplicativoDados,
    id1 = -1,
    id2 = -1,
    viewParams = false
) => {
    let resp = ""
    try {
        if (id1 !== -1) {
            //indo pra tela de status do pedido
            resp += `/${id1}`
            if (id2 !== -1) {
                resp += `/${id2}`
            }
        }
        if (aplicativoDados?.estabelecimentos?.length !== 1) {
            let estabelecimento = verificarLocalStorageEstabelecimentoAtual()

            if (estabelecimento?.codigo) {
                if (id1 !== -1 || id2 !== -1) {
                    //indo pra tela de status
                    resp = `/${estabelecimento.codigo}${resp}`
                } else {
                    //outras telas
                    resp += `?loja=${estabelecimento.codigo}`
                }
            } else if (estabelecimento?.id) {
                if (id1 !== -1 || id2 !== -1) {
                    resp = `/${estabelecimento.id}${resp}`
                } else {
                    resp += `?loja=${estabelecimento.id}`
                }
            }
        }

        if (viewParams) {
            resp += "&view=true"
        }
    } catch (error) {}
    return resp
}

/*
    Evitar repetição de codigo, trata a resposta do sistema adm
*/
function tratamentoResponse(response, chamada = "", funcSuccess = () => {}) {
    //, traceId = '') {
    // console.log("traceId 4", traceId)
    if (response.data.codErro > 0) {
        RegistrarLogErro(
            response.data.mensagem,
            chamada ?? "tratamentoResponse",
            response.config?.data
        ) //, traceId)
        return {
            retornoErro: response.data.codErro,
            mensagem: response.data.mensagem,
        }
    } else if (typeof response.data === "string" && response.data !== "") {
        RegistrarLogErro(response.data, chamada ?? "tratamentoResponse", response.config?.data) //, traceId)
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    } else {
        funcSuccess()
        return response.data
    }
}

/*
    Evitar repetição de codigo, trata o erro na chamada da requisição
*/
const ErroRequisicao = function (erro, aplicativoDados = {}, chamada = "", data = "") {
    //, traceId = '') {
    try {
        console.warn("ErroRequisicao> ", erro.request, erro.message)
        RegistrarLogErro(
            erro.message,
            `ErroRequisicao1${chamada !== "" ? " - " + chamada : ""}`,
            data
        ) //, traceId)
        if (erro.message === "Network Error") {
            return "Verifique sua conexão!"
        } else if (erro.message) {
            return "Erro: " + erro.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, `ErroRequisicao1${chamada !== "" ? " - " + chamada : ""}`, data) //, traceId)
    }
}

/*
    Apenas para remover acentos de strings
*/
export function removeAcento(text) {
    if (!text) return ""

    text = text.toLowerCase()
    text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a")
    text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e")
    text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i")
    text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o")
    text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u")
    text = text.replace(new RegExp("[Ç]", "gi"), "c")
    return text
}

export const Buscardescontos = async function (idEstabelecimento, aplicativoDados) {
    try {
        const response = await api
            .get(`integracao/buscardescontos/${idEstabelecimento}/${aplicativoDados.appNome}`, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "Buscardescontos", {
                    idEstabelecimento,
                    aplicativoDados,
                })
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("Buscardescontos => ", response)

        return tratamentoResponse(response, "Buscardescontos")
    } catch (error) {
        RegistrarLogErro(error, "Buscardescontos", { idEstabelecimento, aplicativoDados })
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const Autenticar_V2 = async function (data = {}, aplicativoDados) {
    try {
        const response = await api
            .post(`clientefiel/oauth/Autenticar_V2`, data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "oauth/Autenticar_V2", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("oauth/Autenticar_V2 => ", response)

        if (response.data[0].codErro > 0) {
            return {
                retornoErro: response.data[0].codErro,
                mensagem: response.data[0].mensagem,
            }
        } else if (typeof response.data === "string" && response.data !== "") {
            return {
                retornoErro: 1,
                mensagem:
                    +"oauth/Autenticar_V2" +
                    " - Erro inesperado, entre em contato com os reponsáveis.",
            }
        } else {
            return response.data
        }
    } catch (error) {
        RegistrarLogErro(error, "oauth", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
export const PagSeguro = async function (id, aplicativoDados) {
    try {
        const response = await api
            .get(`comum/pagseguro/publickey/${id}`, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "pagseguro/publickey", id)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("pagseguro => ", response)

        return tratamentoResponse(response, "pagseguro")
    } catch (error) {
        RegistrarLogErro(error, "pagseguro", id)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterEnderecosComTaxaEntrega = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/ObterEnderecosComTaxaEntrega", data, {
                headers: { "Content-Type": "application/json", app: aplicativoDados.appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "ObterEnderecosComTaxaEntrega",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterEnderecosComTaxaEntrega => ", response)

        return tratamentoResponse(response, "ObterEnderecosComTaxaEntrega")
    } catch (error) {
        RegistrarLogErro(error, "ObterEnderecosComTaxaEntrega", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CalculaEnderecoUsuarioSemUsuario = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post(
                "cliente/CalculaEnderecoUsuarioSemUsuario" + "/" + data.estabelecimentoAtual.id,
                data.endereco,
                {
                    headers: { "Content-Type": "application/json", app: aplicativoDados.appNome },
                }
            )
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "CalculaEnderecoUsuarioSemUsuario",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("CalculaEnderecoUsuarioSemUsuario => ", response)

        return tratamentoResponse(response, "CalculaEnderecoUsuarioSemUsuario")
    } catch (error) {
        RegistrarLogErro(error, "CalculaEnderecoUsuarioSemUsuario", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    1º cadastrar o pedido
*/
export const CadastrarPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/CadastrarPedido", data, {
                headers: { "Content-Type": "application/json", "x-api-version": 1.3 },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "cliente/CadastrarPedido", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("CadastrarPedido => ", response)

        return tratamentoResponse(response, "CadastrarPedido")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    Cancelar pedido
*/
export const CancelarPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/CancelarPedido", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "cliente/CancelarPedido", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("CancelarPedido => ", response)

        return tratamentoResponse(response, "CancelarPedido")
    } catch (error) {
        RegistrarLogErro(error, "CancelarPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    Atualizar status
*/
export const AtualizarStatusPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/AtualizarStatusPedido", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/AtualizarStatusPedido",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("AtualizarStatusPedido => ", response)

        return tratamentoResponse(response, "AtualizarStatusPedido")
    } catch (error) {
        RegistrarLogErro(error, "AtualizarStatusPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    extrato cashback
*/
export const ExtratoCashback = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get("clientefiel/ExtratoCashback/" + data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ExtratoCashback",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ExtratoCashback => ", response)

        return tratamentoResponse(response, "ExtratoCashback")
    } catch (error) {
        RegistrarLogErro(error, "ExtratoCashback", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    verificar se um estabelecimento está aberto
*/
export const ObterStatusPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get("cliente/ObterStatusPedido/" + data, {
                headers: { "Content-Type": "application/json", "app-version": 1.0 },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/ObterStatusPedido",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterStatusPedido => ", response)

        return tratamentoResponse(response, "ObterStatusPedido")
    } catch (error) {
        RegistrarLogErro(error, "ObterStatusPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    verificar se um estabelecimento está aberto
*/
export const EstabelecimentoAberto = async function (data, aplicativoDados) {
    /* var sucesso = true;
    try {
        const response = await s3api.get('EstabelecimentoAberto/' + data + ".js", {
            headers: { "Content-Type": "application/json" }
        }).catch(function (error) {
            const resp = ErroRequisicao(error, aplicativoDados, 'EstabelecimentoAberto/', data)
            sucesso = false;
        });

        if (sucesso) {
            return tratamentoResponse(response, 'EstabelecimentoAberto')
        }
        
    } catch (error) {
        sucesso = false;
    }*/

    try {
        const response = await api
            .get("clientefiel/EstabelecimentoAberto/" + data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/EstabelecimentoAberto/",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("EstabelecimentoAberto => ", response)

        return tratamentoResponse(response, "EstabelecimentoAberto")
    } catch (error) {
        RegistrarLogErro(error, "EstabelecimentoAberto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    buscar ultimo pedido do cliente nas ultimas x horas
*/
export const BuscarUltimoPedidoCliente = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/BuscarUltimoPedidoCliente", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/BuscarUltimoPedidoCliente",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("BuscarUltimoPedidoCliente => ", response)

        return tratamentoResponse(response, "BuscarUltimoPedidoCliente")
    } catch (error) {
        RegistrarLogErro(error, "BuscarUltimoPedidoCliente", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    realizar login, usuario e senha, telefone e etc...
*/
export const LoginGeral = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("clientefiel/LoginGeral", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "clientefiel/LoginGeral", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("LoginGeral => ", response)

        return tratamentoResponse(response, "LoginGeral")
    } catch (error) {
        RegistrarLogErro(error, "LoginGeral", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const AtualizarDispositivoUsuario = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/AtualizarDispositivoUsuario", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/AtualizarDispositivoUsuario",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("AtualizarDispositivoUsuario => ", response)

        return tratamentoResponse(response, "LoginGeral")
    } catch (error) {
        RegistrarLogErro(error, "AtualizarDispositivoUsuario", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    buscar aplicativo dados
*/
export const AplicativoDadosUrl = async function (data, appNome) {
    try {
        console.warn("AplicativoDadosUrl", data, appNome)
        const response = await api
            .get("clientefiel/AplicativoDadosUrl/" + data, {
                headers: { "Content-Type": "application/json" },
                // headers: {"Content-Type": "application/json", "app": appNome}
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    { appNome: appNome },
                    "clientefiel/AplicativoDadosUrl/",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("AplicativoDadosUrl => ", response)

        return tratamentoResponse(response, "AplicativoDadosUrl")
    } catch (error) {
        RegistrarLogErro(error, "AplicativoDadosUrl", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    retorna todos os bairros daquele cep
*/
export const ComumCep = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get("comum/Cep/" + data, {
                headers: { "Content-Type": "application/json", app: aplicativoDados.appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "comum/Cep/", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("comum/Cep/ => ", response)

        return tratamentoResponse(response, "ComumCep")
    } catch (error) {
        RegistrarLogErro(error, "ComumCep", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CidadesAtendidasPorAplicativo = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("comum/CidadesAtendidasPorAplicativo/", data, {
                headers: { "Content-Type": "application/json", app: aplicativoDados.appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "comum/CidadesAtendidasPorAplicativo",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("comum/CidadesAtendidasPorAplicativo/ => ", response)

        return tratamentoResponse(response, "CidadesAtendidasPorAplicativo")
    } catch (error) {
        RegistrarLogErro(error, "CidadesAtendidasPorAplicativo", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const Bairros = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("comum/Bairros/", data, {
                headers: { "Content-Type": "application/json", app: aplicativoDados.appNome },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "comum/Bairros", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("comum/Bairros/ => ", response)

        return tratamentoResponse(response, "Bairros")
    } catch (error) {
        RegistrarLogErro(error, "Bairros", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const listarClientes = async function (data) {
    try {
        const response = await api.post("clientefiel/ListarClientesFiltro", data, {
            headers: { "Content-Type": "application/json" },
        })

        console.log("ListarClientesFiltro => ", response)

        return tratamentoResponse(response, "ListarClientesFiltro")
    } catch (error) {
        RegistrarLogErro(error, "hostname", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const verificarClientes = async function (data, appNome) {
    try {
        const response = await api.post("clientefiel/ValidarExistenciaUsarios", data, {
            headers: { "Content-Type": "application/json", app: appNome },
        })

        console.log("ValidarExistenciaUsarios => ", response)

        return tratamentoResponse(response, "ValidarExistenciaUsarios")
    } catch (error) {
        RegistrarLogErro(error, "verificarClientes", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    buscar cardapio para pedido de mesa
*/
export const ObterCupomDescontoCompleto = async function (data, aplicativoDados) {
    try {
        for (let index = 0; index < data?.itens?.length; index++) {
            delete data.itens[index].idEstabelecimento
        }
        const response = await api
            .post("cliente/ObterCupomDescontoCompleto", data, {
                headers: { "Content-Type": "application/json", plataforma: "site" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/ObterCupomDescontoCompleto",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterCupomDescontoCompleto => ", response)

        return tratamentoResponse(response, "ObterCupomDescontoCompleto")
    } catch (error) {
        RegistrarLogErro(error, "ObterCupomDescontoCompleto", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const eventoAddToCart = () => {
    try {
        window.fbq("track", "AddToCart")
    } catch (error) {}
}

export const eventoPurchase = valorTotalPixelFacebook => {
    try {
        if (window.fbq) {
            window.fbq("track", "Purchase", { currency: "BRL", value: valorTotalPixelFacebook })
        } else {
            RegistrarLogErro({ mensagem: "Não há window.fbq" }, "Pixel Purchase - Sem fbq", {
                value: valorTotalPixelFacebook,
            })
        }
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "Pixel Purchase - Error", {
            value: valorTotalPixelFacebook,
        })
    }
}

export const eventoCompleteRegistration = () => {
    try {
        window.fbq("track", "CompleteRegistration")
    } catch (error) {}
}
export const eventoCheckout = () => {
    try {
        window.fbq("track", "InitiateCheckout")
    } catch (error) {}
}

export const eventoAddPaymentInfo = () => {
    try {
        window.fbq("track", "AddPaymentInfo")
    } catch (error) {}
}

/*
    buscar cardapio para pedido de mesa
*/
export const ObterCardapioPedidoMesa = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("clientefiel/ObterCardapioPedidoMesa", data, {
                headers: { "Content-Type": "application/json", plataforma: "site" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ObterCardapioPedidoMesa",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterCardapioPedidoMesa => ", response)

        return tratamentoResponse(response, "ObterCardapioPedidoMesa", atualizarDataCardapio)
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapioPedidoMesa", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    buscar cardapio para pedido de mesa
*/
export const ObterProdutosPaginado = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("/produto/ObterProdutosPaginado", data, {
                headers: { "Content-Type": "application/json", plataforma: "site" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "produto/ObterProdutosPaginado",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })
        console.warn("ObterProdutosPaginado => ", response)
        return tratamentoResponse(response, "ObterProdutosPaginado", () => {})
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapioPedidoMesa", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const removerProdutosCategoria = ({ cardapio }) => {
    cardapio?.categorias?.forEach((categoria, index) => {
        if (index != 0) {
            categoria.produtos = []
        }
    })
}

/* 
ObterCardapioCompletoV1 retorna um json com as categorias dentro diferente do ObterCardapio
*/
export const ObterCardapioCompletoV1 = async function (data, aplicativoDados) {
    // const traceId = crypto.randomUUID()
    // console.log("traceId 0", traceId)

    try {
        const response = await api
            .post("clientefiel/ObterCardapioCompletoV1", data, {
                headers: {
                    "Content-Type": "application/json",
                    plataforma: "site",
                    // "Busca-Paginada": true,
                    "app-version": 1.0,
                    //"traceId": traceId
                },
            })
            .catch(function (error) {
                //console.log("traceId 2", traceId)
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ObterCardapioCompletoV1",
                    data
                ) //, traceId)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterCardapioCompletoV1 => ", data.idEstabelecimento, response)

        return tratamentoResponse(response, "ObterCardapioCompletoV1", atualizarDataCardapio) //, traceId)
    } catch (error) {
        // console.log("traceId 1", traceId)
        RegistrarLogErro(error, "ObterCardapioCompletoV1", data) //, traceId)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/* 
    cadastrar cartoes de pagamento online
*/
export const CadastrarCartaoCliente = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/CadastrarCartaoCliente", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/CadastrarCartaoCliente",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("CadastrarCartaoCliente => ", response)

        return tratamentoResponse(response, "CadastrarCartaoCliente")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarCartaoCliente", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/* 
    retorna todos os cartoes salvos do usuario
*/
export const BuscarPedidosCliente = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/BuscarPedidosCliente", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/BuscarPedidosCliente",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("BuscarPedidosCliente => ", response)

        return tratamentoResponse(response, "BuscarPedidosCliente")
    } catch (error) {
        RegistrarLogErro(error, "BuscarPedidosCliente", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export async function ObterPedido(data, aplicativoDados) {
    try {
        const response = await api
            .get("cliente/ObterPedido/" + data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "cliente/ObterPedido")
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterPedido => ", response)

        return tratamentoResponse(response, "ObterPedido")
    } catch (error) {
        RegistrarLogErro(error, "ObterPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/* 
    retorna todos os cartoes salvos do usuario
*/
export const ObterCartoesCredito = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/ObterCartoesCredito", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/ObterCartoesCredito",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterCartoesCredito => ", response)

        return tratamentoResponse(response, "ObterCartoesCredito")
    } catch (error) {
        RegistrarLogErro(error, "ObterCartoesCredito", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const DeletarCartaoCredito = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/DeletarCartaoCredito", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/DeletarCartaoCredito",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("DeletarCartaoCredito => ", response)

        return tratamentoResponse(response, "DeletarCartaoCredito")
    } catch (error) {
        RegistrarLogErro(error, "DeletarCartaoCredito", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/* 
ObterCardapio retorna um array de categorias diferente do ObterCardapioCompletoV1
*/
export const ObterCardapio = async function (data, aplicativoDados) {
    console.warn("ObterCardapio antes", data)
    try {
        const response = await api
            .post("clientefiel/ObterCardapio", data, {
                headers: { "Content-Type": "application/json", plataforma: "site" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ObterCardapio",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterCardapio => ", response)

        return tratamentoResponse(response, "ObterCardapio", atualizarDataCardapio)
    } catch (error) {
        RegistrarLogErro(error, "ObterCardapio", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    cadastrar endereço em um cliente
*/
export const CadastrarEnderecoCliente = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/CadastrarEnderecoCliente", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/CadastrarEnderecoCliente",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("CadastrarEnderecoCliente => ", response)

        window.dispatchEvent(new CustomEvent("cadastrarEndereco"))

        return tratamentoResponse(response, "CadastrarEnderecoCliente")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarEnderecoCliente", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    buscar estabelecimentos que entregam naquele endereço
*/
export const Post_ListarEstabelecimentosPorEndereco = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("clientefiel/ListarEstabelecimentosPorEndereco", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ListarEstabelecimentosPorEndereco",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ListarEstabelecimentosPorEndereco => ", response)

        return tratamentoResponse(response, "ListarEstabelecimentosPorEndereco")
    } catch (error) {
        RegistrarLogErro(error, "ListarEstabelecimentosPorEndereco", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    CadastrarUsuario
*/
export const CadastrarUsuario = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/CadastrarUsuario", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cliente/CadastrarUsuario",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("CadastrarUsuario => ", response)

        return tratamentoResponse(response, "CadastrarUsuario")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarUsuario", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const RegistrarCupom = async function (data, aplicativoDados) {
    try {
        var url = "cupom/GerarCupomAleatorio?"
        url += "idEstabelecimento=" + data.idEstabelecimento
        url += "&descontoValor=" + data.descontoValor
        url += "&descontoPercentual=" + data.descontoPercentual
        url += "&diasValidade=" + data.diasValidade
        url += "&nome=" + data.nome
        url += "&email=" + data.email
        url += "&telefone=" + data.telefone

        const response = await api
            .post(url, data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "cupom/GerarCupomAleatorio",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("GerarCupomAleatorio => ", response)

        return tratamentoResponse(response, "GerarCupomAleatorio")
    } catch (error) {
        RegistrarLogErro(error, "GerarCupomAleatorio", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    RegistrarSeloSeguro2
*/
export const RegistrarSeloSeguro2 = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("clientefiel/RegistrarSeloSeguro2", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/RegistrarSeloSeguro2",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("RegistrarSeloSeguro2 => ", response)

        return tratamentoResponse(response, "RegistrarSeloSeguro2")
    } catch (error) {
        RegistrarLogErro(error, "RegistrarSeloSeguro2", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    RegistrarSeloSeguro2
*/
export const ResgatarCashBack = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("clientefiel/ResgatarCashBack", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ResgatarCashBack",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ResgatarCashBack => ", response)

        return tratamentoResponse(response, "ResgatarCashBack")
    } catch (error) {
        RegistrarLogErro(error, "ResgatarCashBack", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    AvaliarPedido
*/
export const AvaliarPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("avaliacao/AvaliarPedido", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "avaliacao/AvaliarPedido", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("AvaliarPedido => ", response)

        return tratamentoResponse(response, "AvaliarPedido")
    } catch (error) {
        RegistrarLogErro(error, "AvaliarPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

/*
    Redefinir senha
*/
export const RedefinirSenha = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/RedefinirSenha", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "cliente/RedefinirSenha", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("RedefinirSenha => ", response)

        return tratamentoResponse(response, "RedefinirSenha")
    } catch (error) {
        RegistrarLogErro(error, "RedefinirSenha", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export async function getGetNetCredentials(data, aplicativoDados) {
    try {
        const response = await api
            .get("getnet/auth/credenciais/" + data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "cliente/getGetNetCredentials")
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("getGetNetCredentials => ", response)

        return tratamentoResponse(response, "getGetNetCredentials")
    } catch (error) {
        RegistrarLogErro(error, "getGetNetCredentials", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const verifyNotification = aplicativoDados => {
    // if( (window.location.hostname).includes("pablodelivery") ){
    var myRequest = new Request(
        `https://wapi.appclientefiel.com.br/rest/clientefiel/FirebaseWeb/${window.location.hostname}`
    )
    // var myRequest = new Request(`https://wapi.appclientefiel.com.br/rest/clientefiel/FirebaseWeb/arabis.pedidosite.com.br`);
    fetch(myRequest)
        .then(function (response) {
            return response.text().then(function (data) {
                if (data !== "") {
                    firebase.initializeApp(JSON.parse(data))
                    const messaging = firebase.messaging()

                    function IntitalizeFireBaseMessaging() {
                        messaging
                            .requestPermission()
                            .then(function () {
                                console.warn("Notification Permission")
                                return messaging.getToken()
                            })
                            .then(function (token) {
                                console.warn("Token : " + token)
                                localStorage.setItem("tokenNotificationFirebase", token)
                            })
                            .catch(function (reason) {
                                console.warn(reason)
                            })
                    }

                    messaging.onMessage(async function (payload) {
                        var icon = ""
                        try {
                            icon = aplicativoDados?.urlLogo
                        } catch (error) {}

                        console.warn("onMessage", payload)
                        const notificationOption = {
                            body: payload.notification.body,
                            icon: icon ? icon : payload.notification.image,
                            image: payload.notification.image,
                        }

                        const reg = await navigator.serviceWorker.getRegistration()

                        reg.showNotification(payload.notification.title, notificationOption)

                        reg.onclick = function (ev) {
                            ev.preventDefault()
                            window.open(payload.notification.click_action, "_blank")
                            reg.close()
                        }
                    })

                    messaging.onTokenRefresh(function () {
                        messaging
                            .getToken()
                            .then(function (newtoken) {
                                console.warn("New Token : " + newtoken)
                                localStorage.setItem("tokenNotificationFirebase", newtoken)
                            })
                            .catch(function (reason) {
                                console.warn(reason)
                            })
                    })
                    IntitalizeFireBaseMessaging()
                }
            })
        })
        .catch(console.error)
    // }
}

export const ObterDetalhesEstabelecimento = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get(
                "clientefiel/ObterEstabelecimentoDetalhes/" + data,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .catch(function (error) {
                const resp = ErroRequisicao(
                    error,
                    aplicativoDados,
                    "clientefiel/ObterEstabelecimentoDetalhes",
                    data
                )
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterEstabelecimentoDetalhes => ", response)

        return tratamentoResponse(response, "ObterEstabelecimentoDetalhes")
    } catch (error) {
        RegistrarLogErro(error, "ObterEstabelecimentoDetalhes", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const ObterParametrosEstabelecimento = async function (data, aplicativoDados) {
    try {
        const response = await api
            .get(
                "comum/parametros/" + data,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .catch(function (error) {
                const resp = ErroRequisicao(error, aplicativoDados, "comum/parametros", data)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.warn("ObterParametrosEstabelecimento => ", response)

        return tratamentoResponse(response, "ObterParametrosEstabelecimento")
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "ObterParametrosEstabelecimento", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const getJSONParametros = async function (data, aplicativoDados) {
    try {
        const arrayParametros = await ObterParametrosEstabelecimento(data, aplicativoDados)

        if (!arrayParametros || !Array.isArray(arrayParametros)) return {}

        return arrayParametros.reduce((obj, param) => {
            if (param.valor === "true" || param.valor === "false")
                obj[param.nome] = JSON.parse(param.valor)
            else if (isNumeric(param.valor)) obj[param.nome] = Number(param.valor)
            else obj[param.nome] = param.valor

            return obj
        }, {})
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "getJSONParametros", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export async function getResultsMapBoxSearch(searchString) {
    try {
        return await api.get("https://api.mapbox.com/geocoding/v5/mapbox.places/" + searchString)
    } catch (error) {
        console.error(error)
        RegistrarLogErro(error, "getResultsMapBoxSearch", searchString)
    }
}

const BRLFormatter = Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" })
const ARSFormatter = Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" })

export function formatarParaDinheiro(valor) {
    const parametros = JSON.parse(localStorage.getItem("custom-params"))
    const moeda = parametros?.PUBLIC_CODIGO_MOEDA ?? "BRL"

    switch (moeda) {
        case "BRL":
            return BRLFormatter.format(valor)
        case "ARS":
            return ARSFormatter.format(valor)
        case "TESTE":
            return BRLFormatter.format(valor)
        default:
            return Intl.NumberFormat("pt-BR", { style: "currency", currency: moeda })
    }
}

export function formatarCpf(cpf) {
    if (!cpf || typeof cpf !== "string") return cpf

    const string = cpf?.replaceAll(/[^0-9]/g, "")

    return string?.length > 3
        ? string.slice(0, 3) +
              "." +
              (string.length > 6
                  ? string.slice(3, 6) +
                    "." +
                    (string.length > 9
                        ? string.slice(6, 9) + "-" + string.slice(9, 11)
                        : string.slice(6))
                  : string.slice(3))
        : string
}

export function RegistrarLogErro(erro, functionName, dataFuncao = "") {
    //, traceId = '') {

    let stringErro = erro.stack
        ? erro.mensagem + "  " + erro.stack
        : typeof erro === "string"
        ? erro
        : typeof JSON.stringify(erro, Object.getOwnPropertyNames(erro)) === "string"
        ? JSON.stringify(erro, Object.getOwnPropertyNames(erro)) +
          (erro.mensagem ? "\n\n\nMensagem: " + erro.mensagem : "")
        : erro.mensagem

    if (typeof stringErro === "object") stringErro = JSON.stringify(stringErro)

    if (dataFuncao !== "" && typeof dataFuncao === "string")
        stringErro = stringErro.concat(`\n\ndata = `).concat(dataFuncao) // (${traceId})
    if (dataFuncao !== "" && typeof dataFuncao === "object")
        stringErro = stringErro.concat(`\n\ndata = `).concat(JSON.stringify(dataFuncao)) // (${traceId})

    const data = {
        identificador:
            functionName +
            " - V" +
            Package.version +
            " - " +
            window.location.href.replace("http://", ""),
        erro: stringErro,
    }

    api.post("log/front/registrar", data, {
        headers: { "Content-Type": "application/json" },
    })
}
